import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseModal from '@/components/base/baseModal';
import { MAX_RECONNECT_COUNT } from '@/models/socket';
import { Button, Progress } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const { reconnectCount, setReconnectCount } = useModel('socket');
  const isOpen = useMemo(() => {
    return reconnectCount > MAX_RECONNECT_COUNT;
  }, [reconnectCount]);
  const onClose = () => {
    setReconnectCount(0);
  };
  return (
      <BaseModal
          // isOpen={isOpen}
          isOpen={false}
          placement="center"
          onClose={onClose}
          clickMaskClose={true}
          classNames={{
            modal: 'border-none px-4',
            title: 'py-0 m-0',
          }}
      >
        <div className="w-[80vw] flex flex-col gap-2 pb-6 sm:w-[500px]">
          <div className="flex text-base mb-2 justify-between">
            <span>{t('正在重新连接')}</span>
          </div>
          <Progress
              size="sm"
              color="warning"
              isIndeterminate
              aria-label="Loading..."
              className="max-w-md"
          />
          <p>{t('连接中断！正在重新连接，请确认网络状况是否正常。')}</p>
          <Button
              spinner={<LoadingSvg />}
              onClick={() => {
                location.reload();
              }}
              className=" mainColorButton"
          >
            {t('重新连接')}
          </Button>
        </div>
      </BaseModal>
  );
};
