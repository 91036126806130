import { tabBarRoutes } from '@/router/tabBarRoutes';
import { useModel } from '@@/exports';
import { history, useRouteProps } from '@umijs/max';
import Lottie from 'lottie-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import style from './TabBar.less';
import { getIsWebView } from '@/utils'; // 引入 less 文件
/**
 * 该组件定义了一个TabBar，基于当前路由和预定义的tabBarRoutes来渲染导航选项。
 * 通过检查当前路由是否在白名单内，决定是否渲染导航栏，并为当前选中的选项应用样式。
 *
 * 无参数。
 * 返回值: 返回渲染的TabBar组件。
 */
export default () => {
  // 获取当前的路由信息
  const currentRoute: any = useRouteProps() || {};
  // 使用i18n功能
  const { t } = useTranslation();

  // 将基础路由映射为路径列表，用于后续的白名单检查
  const whiteList = tabBarRoutes.map((item) => item.path);

  // 如果当前路由在白名单内，则渲染导航栏
  const { isHideBottomNav } = useModel('compontentSetting');
  const { reLoadOrder } = useModel('system');

  const lottieRefs = useRef(tabBarRoutes.map(() => React.createRef()));

  useEffect(() => {
    // 在初始化时，确保所有动画都停止在第一帧
    lottieRefs.current.forEach((ref) => {
      const lottieInstance: any = ref.current;
      if (lottieInstance) {
        lottieInstance.goToAndStop(0, true);
      }
    });
  }, []);

  const handleItemClick = (index: number, path: any) => {
    // 避免重复点击
    if (location.pathname === path) return;
    // reLoadOrder();
    const currentLottie: any = lottieRefs.current[index].current;

    if (currentLottie) {
      currentLottie.goToAndStop(0, true); // 确保动画从头开始
      currentLottie.play();
    }

    history.replace(path);
  };
  const isWebView = getIsWebView()

  return (
    whiteList.includes(currentRoute.path) &&
    (!isHideBottomNav || !isWebView) && (
      <nav className="bg-background fixed bottom-0 z-20 w-full h-[60px]">
        <ul className="h-full flex justify-around items-center mb-[0]">
          {tabBarRoutes.map((route, index) => (
            // 对每个路由选项进行渲染，点击时跳转到对应的路径
            <li
              key={route.path}
              onClick={() => handleItemClick(index, route.path)}
              className={`flex text-[10px] flex-1 flex-col items-center 
                        ${
                          currentRoute.path === route.path
                            ? '!bottomTitleSelectText'
                            : '!bottomTitleUnSelectText'
                        } ${style['li']}`}
            >
              <div
                className={`flex flex-col items-center ${
                  currentRoute.path === route.path
                    ? style['selected']
                    : style['unselected']
                }`}
              >
                <Lottie
                  lottieRef={lottieRefs.current[index] as any}
                  className="lottie-animation"
                  style={{ width: 22, height: 22 }}
                  animationData={route.icon}
                  loop={false} // 关闭循环
                  autoplay={false} // 关闭自动播放
                />
                <span className="text-center mt-1">{t(route.i18n)}</span>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    )
  );
};
