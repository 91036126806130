'use client';
import { NextUIProvider } from '@nextui-org/system';
import { ThemeProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';

/**
 * Providers组件
 * 为应用提供主题和NextUIProvider的高阶组件。
 *
 * @param {ProvidersProps} props 组件接收的props
 * @param {React.ReactNode} props.children 子组件，将被包裹在Providers组件内
 * @param {ThemeProviderProps} [props.themeProps] 主题相关的props，可选
 * @returns 返回渲染好的Providers组件，包括NextUIProvider和ThemeProvider
 */
export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

export default ({ children, themeProps }: ProvidersProps) => {
  // 使用NextUIProvider和ThemeProvider包裹children，提供全局样式和主题支持
  return (
    <NextUIProvider className="h-full bg-background">
      <ThemeProvider
        attribute="class"
        storageKey="nextTheme"
        defaultTheme="dark"
        {...themeProps}
      >
        {children}
      </ThemeProvider>
    </NextUIProvider>
  );
};
