import {
  FINANCIAL_MANAGEMENT_LIST_ENUM,
  GetCoinListTypeEnum,
} from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { ChartTypeEnum } from '@/models/kline';
import { cn } from '@/utils';
import { Divider, Tooltip } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'umi';

export default ({}) => {
  const { t } = useTranslation();
  // 使用useRouteProps钩子获取当前路由信息，如果没有则返回空对象
  const location = useLocation();
  const { setCoinType } = useModel('kline');
  // const { getShowMenuList } = useModel('appInfo');
  const renderMenuList = [
    {
      label: t('首页'),
      path: PageEnum.PCHOME,
    },

    {
      label: t('现货交易'),
      path: PageEnum.HOME + '?type=' + GetCoinListTypeEnum.COIN,
      type: ChartTypeEnum.COIN,
    },

    {
      label: t('永续合约'),
      path: PageEnum.HOME + '?type=' + GetCoinListTypeEnum.CONTRACT,
      type: ChartTypeEnum.CONTRACT,
    },
    // {
    //   label: t('极速合约'),
    //   path: PageEnum.HOME + '?type=' + GetCoinListTypeEnum.FASTCONTRACT,
    //   type: ChartTypeEnum.FAST_CONTRACT,
    // },
    {
      label: t('C2C交易'),
      path: PageEnum.C2C_LIST,
    },
    {
      label: t('闪兑'),
      path: PageEnum.SWAP,
    },
    {
      label: t('划转'),
      path: PageEnum.TRANSFER,
    },
    {
      label: t('IEO'),
      path: PageEnum.PC_IEO,
    },
    {
      label: t('AI交易'),
      path:
        PageEnum.AITRADE +
        '?type=' +
        FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
    },
    {
      label: t('在线客服'),
      path: PageEnum.ONLINESUPPORT,
    },
    // {
    //   label: t('广场'),
    //   path: PageEnum.AI_ORDER,
    //   children: [
    //     {
    //       icon: MdiCommentQuoteOutline,
    //
    //       label: t('新闻'),
    //       subTabel: t('及时了解加密货币的最新资讯'),
    //       path: PageEnum.NEWS,
    //     },
    //     {
    //       icon: MaterialSymbolsCycle,
    //
    //       label: t('论坛'),
    //       subTabel: t('拓展您的知识储备，获取最新意见'),
    //       path: PageEnum.FORUM,
    //     },
    //   ],
    // },
    {
      label: t('帮助文档'),
      path: PageEnum.HELPARTICLE,
    },
  ];

  const { user } = useModel('user');
  const isLogin = !!user?.id;

  return (
    <div className="pl-4 flex gap-8 ml-4">
      {renderMenuList?.map((item: any) => {
        if (!(item as any)?.children) {
          return (
            <span
              key={item?.path}
              className={cn(
                'text-titleColor text-sm cursor-pointer select-none hover:text-primary font-bold ',
                {
                  '!text-primary':
                    location?.pathname + location?.search === item?.path,
                },
              )}
              onClick={() => {
                if (!isLogin) {
                  history.replace(PageEnum.LOGIN);
                  return;
                }
                if (item?.type) {
                  setCoinType(item?.type);
                }
                history.push(item?.path);
              }}
            >
              {item?.label}
            </span>
          );
        } else {
          return (
            <Tooltip
              key={item?.path}
              className="bg-background text-foreground py-1 rounded-md"
              content={
                <div className="cursor-pointer">
                  {item?.children?.map((childrenItem: any, index: any) => {
                    return (
                      <div key={item?.path}>
                        <div
                          className={cn(
                            'flex hover:bg-backgroundAuxiliaryColor px-2 py-1 rounded-md items-center',
                            {
                              '!text-primary':
                                location?.pathname + location?.search ===
                                childrenItem?.path,
                            },
                          )}
                          onClick={() => {
                            history.push(childrenItem?.path);
                          }}
                        >
                          <childrenItem.icon />
                          <div className="ml-2">
                            <div> {childrenItem?.label}</div>
                            <div className="mt-1 text-auxiliaryTextColor text-xs">
                              {childrenItem?.subTabel}
                            </div>
                          </div>
                        </div>
                        {index < item?.children?.length - 1 && (
                          <Divider className="my-1 !bg-backgroundAuxiliaryColor" />
                        )}
                      </div>
                    );
                  })}
                </div>
              }
            >
              <div className="flex items-center text-titleColor text-sm cursor-pointer select-none hover:text-primary">
                {item?.label}
                {item?.icon && <item.icon className="ml-1" />}
              </div>
            </Tooltip>
          );
        }
      })}
    </div>
  );
};
