import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import { MaterialSymbolsCircleNotificationsOutline } from '@/assets/icons/comm/MaterialSymbolsCircleNotificationsOutline';
import { MaterialSymbolsDownloadRounded } from '@/assets/icons/comm/MaterialSymbolsDownloadRounded';
import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import { FluentDarkTheme20Filled } from '@/assets/icons/layout/FluentDarkTheme20Filled';
import { IcSharpLanguage } from '@/assets/icons/layout/IcSharpLanguage';
import { MaterialSymbolsLogoutRounded } from '@/assets/icons/layout/MaterialSymbolsLogoutRounded';
import { MaterialSymbolsMonitoringRounded } from '@/assets/icons/layout/MaterialSymbolsMonitoringRounded';
import { MaterialSymbolsPaidOutlineRounded } from '@/assets/icons/layout/MaterialSymbolsPaidOutlineRounded';
import { MaterialSymbolsHeadphones } from '@/assets/icons/mine/MaterialSymbolsHeadphones';
import { MaterialSymbolsSettings } from '@/assets/icons/mine/MaterialSymbolsSettings';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import { VerifiedStatesEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import PcNavLink from '@/layouts/components/PcNavLink';
import { basicRoutes } from '@/router/basicRoutes';
import { FormatUtils } from '@/utils/format';
import {
  Badge,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  Switch,
  Tooltip,
} from '@nextui-org/react';
import { history, useModel, useRouteProps } from '@umijs/max';
import { useTheme } from 'next-themes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseBadge from '@/components/base/baseBadge';
import BaseSkeleton from '@/components/base/baseSkeleton';

/**
 * 默认导出一个函数组件，用于根据当前路由和主题渲染导航栏。
 * 该组件会根据当前的主题切换主题，以及在白名单路由之外的地方渲染导航栏。
 *
 * 无参数。
 * 无显式返回值，但会根据条件渲染 Navbar 组件。
 */
export default function TopNav() {
  // 使用useTheme钩子获取当前主题和设置主题的函数
  const { theme, setTheme } = useTheme();
  const { setAppLanguage, language, languageList } = useModel('language');
  // 使用useRouteProps钩子获取当前路由信息，如果没有则返回空对象
  const route: any = useRouteProps() || {};
  // 将基础路由映射为路径列表，用于后续的白名单检查
  const whiteList = basicRoutes?.map((item) => item.path);
  // 处理主题切换的函数
  const handleThemeChange = () => {
    if (theme === ThemeEnum.light) {
      setTheme(ThemeEnum.dark);
    } else {
      setTheme(ThemeEnum.light);
    }
  };
  const { t } = useTranslation();
  const { user } = useModel('user');
  // 检查当前路由是否在白名单内，如果是则渲染导航栏组件
  const [arr] = useState([
    {
      text: t('资产'),
      Icon: MaterialSymbolsPaidOutlineRounded,
      url: PageEnum.FUNDS,
    },
    {
      text: t('资金记录'),
      Icon: MaterialSymbolsMonitoringRounded,
      url: PageEnum.ORDER_HISTORY,
    },
    { text: t('设置'), Icon: MaterialSymbolsSettings, url: PageEnum.SETTINGS },
  ]);

  const { logOut } = useModel('user');
  const {  gotoCustomerService } = useModel('imSocket');
  const { appInfo } = useModel('appInfo');

  // 获得未读数字
  const { noReading } = useModel('socket');
  const { customerServiceMessageUNREAD } = useModel('imSocket') || {};
  const isLogin = !!user?.id;
  // 触发pc 个人信息面板显隐
  const [isOpen,setIsOpen] =useState(false)
  // vip弹框
  return (
    !whiteList.includes(route.path) && (
      <div className="flex max-w-full overflow-x-auto" onClick={(e)=>{setIsOpen(false);e.stopPropagation();}}>
        <Navbar
          maxWidth="full"
          classNames={{ wrapper: 'px-4' }}
          className={`h-[60px]  border-b-[1px] fixed border-backgroundAuxiliaryColor bg-background flex-shrink-0`}
        >
          <NavbarBrand>
            <div className="flex items-center">
              {/* logo */}
              {appInfo.APP_BASE_LOGO&&<img
                src={(appInfo as any)?.APP_BASE_LOGO}
                className="w-[26px] mr-2 cursor-pointer"
                alt="logo"
                onClick={() => {
                  history.push(PageEnum.PCHOME);
                }}
              />}
              {!appInfo.APP_BASE_LOGO&&
                <div  className='w-fit cursor-pointer' onClick={() => {
                  history.push(PageEnum.PCHOME);
                }}>
                  <BaseSkeleton isLoaded={!appInfo.APP_BASE_LOGO}>
                    <div className=" flex justify-between bg-auxiliaryTextColor w-[27px] rounded-md h-[22px]">
                      <span className="!text-[8px] font-bold text-background">
                        &nbsp;
                        {t("加载中")}
                      </span>
                    </div>
                  </BaseSkeleton>
                </div>
              }


              <PcNavLink></PcNavLink>
            </div>
          </NavbarBrand>

          <NavbarContent justify="end" className="mb-0 text-foreground">
            {isLogin && (
              <div
                className="flex items-center text- text-primaryButtonTextColor bg-primary rounded-md justify-center  !py-0 h-[26px] w-[70px] text-xs cursor-pointer"
                onClick={() => {
                  history.push(PageEnum.TopUp);
                }}
              >
                <MaterialSymbolsDownloadRounded width="1.2em" height="1.2em" />
                <span className="select-none">{t('充值')}</span>
              </div>
            )}

            {/* 国际化 */}
            <Dropdown
              classNames={{
                content: 'bg-background',
                base: 'max-h-[70vh] overflow-y-auto',
              }}
            >
              <DropdownTrigger>
                <div className="flex items-center text-titleColor text-xs cursor-pointer select-none">
                  <IcSharpLanguage className="!text-2xl cursor-pointer" />
                </div>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                {languageList.map((item: any) => {
                  return (
                    <DropdownItem key={item.code}>
                      <div
                        className={`flex  p-3 rounded-md justify-between cursor-pointer`}
                        key={item.code}
                        onClick={() => {
                          setAppLanguage(item.code);
                        }}
                      >
                        <div className="flex text-foreground">
                          <img
                            src={item.avatar}
                            className="object-cover"
                            width={32}
                            height={24}
                            alt=''
                          />
                          <span className="ml-2">{item.name}</span>
                        </div>

                        {language === item.code && (
                          <IconParkSolidCorrect className="text-primary ml-4" />
                        )}
                      </div>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>

            {isLogin && (
              <>
                <Tooltip
                  isOpen={isOpen}
                  className="bg-background text-foreground rounded-md"
                  content={
                    <div className="cursor-pointer">
                      <div
                        className="flex items-center pt-1 pl-4 py-1 rounded-md"
                        onClick={() => {
                          history.push(PageEnum.MINE);
                        }}
                      >
                        {user?.name}
                      </div>
                      <div
                        className="flex items-center pl-4 gap-1 text-foreground py-[2px]"
                        onClick={() => {
                          FormatUtils.copyText(user?.account);
                        }}
                      >
                        <span>{user?.account}</span>
                        <PhCopyFill />
                      </div>
                      {/* 用户状态显示 ，vip状态显示 */}
                      <div
                        className="flex mt-[2px] pr-4 pl-4"
                        onClick={() => {
                          // onOpen();
                          history.push(PageEnum.APPLY_VIP);
                        }}
                      >
                        {user?.realnameStatus === VerifiedStatesEnum.REAL && (
                          <span className="bg-success/10 text-success mr-1 rounded-md px-1">
                            {t('已认证')}
                          </span>
                        )}
                        {user?.realnameStatus === VerifiedStatesEnum.UNDER && (
                          <span className="bg-warning/10 text-warning mr-1 rounded-md px-1">
                            {t('审核中')}
                          </span>
                        )}
                        {user?.realnameStatus === VerifiedStatesEnum.UNREAL && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(PageEnum.VERIFIED);
                            }}
                            className="bg-errorColor/10 text-errorColor mr-1 rounded-md px-1"
                          >
                            {t('未认证')}
                          </span>
                        )}

                        {+user?.reservedField1 < 1 && (
                          <span className="bg-primary/10 bg-primary ml-1 rounded-md px-1">
                            {t('普通用户')}
                          </span>
                        )}

                        {+user?.reservedField1 >= 1 && (
                          <div className="flex items-end relative">
                            <img
                              src={require('@/assets/img/vip/vip.png')}
                              alt="VIP Logo"
                              className="w-[20px] relative z-4 ml-2 mt-1 "
                            />
                            <div className="bg-gradient-to-r  text-xxs -ml-1 from-[#3E3525] to-[#DAB76B] text-[#FFE5AE] rounded-r-2xl px-2 ">
                              VIP{+user?.reservedField1}
                            </div>
                          </div>
                        )}
                      </div>
                      <Divider className="mt-2 !bg-backgroundAuxiliaryColor" />
                      {arr.map((item) => {
                        return (
                          <div
                            key={item.text}
                            className={`flex selected-none pl-4 justify-between text-center cursor-pointer items-center  pr-2 rounded-bl-none rounded-tl-none py-2  hover:bg-backgroundAuxiliaryColor hover:text-backContrastColor`}
                            onClick={() => {
                              history.push(item.url);
                            }}
                          >
                            <div className="flex items-center">
                              <item.Icon />
                              <div className="!text-xs pl-2 select-none">
                                {item.text}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className={`flex selected-none pl-4 justify-between text-center cursor-pointer items-center  pr-2 rounded-bl-none rounded-tl-none py-2  hover:bg-backgroundAuxiliaryColor hover:text-backContrastColor`}
                        onClick={() => {
                          handleThemeChange();
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center mr-4">
                            <FluentDarkTheme20Filled />
                            <div className="!text-xs pl-2 select-none">
                              {t('黑夜模式')}
                            </div>
                          </div>
                          <Switch
                            size="sm"
                            className="!w-[20px] mr-3"
                            classNames={{
                              wrapper: 'w-[40px] h-[20px]',
                            }}
                            isSelected={theme === ThemeEnum.dark}
                            onClick={handleThemeChange}
                          />
                        </div>
                      </div>
                      <Divider className="mt-2 !bg-backgroundAuxiliaryColor" />
                      <div
                        className={`flex selected-none pl-4 justify-between text-center cursor-pointer items-center  pr-2 rounded-bl-none rounded-tl-none py-2  hover:bg-backgroundAuxiliaryColor hover:text-backContrastColor`}
                        onClick={() => {
                          logOut();
                        }}
                      >
                        <div className="flex items-center">
                          <MaterialSymbolsLogoutRounded />
                          <div className="!text-xs pl-2 select-none">
                            {t('退出登录')}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <div className="flex items-center text-titleColor text-xs cursor-pointer select-none" onClick={(e)=>{
                    setIsOpen(!isOpen)
                    e.stopPropagation()
                  }}>
                    <BasilUserSolid className="!text-2xl ml-2 cursor-pointer hover:text-primary" />
                  </div>
                </Tooltip>
                {/* //消息通知 */}
                <BaseBadge
                  onClick={() => {
                    gotoCustomerService();
                  }}
                  content={+customerServiceMessageUNREAD}
                >
                  <MaterialSymbolsHeadphones
                    className="!text-2xl cursor-pointer "
                    onClick={() => {
                      gotoCustomerService();
                    }}
                  />
                </BaseBadge>
                <BaseBadge
                  onClick={() => {
                    history.push(PageEnum.MESSAGE);
                  }}
                  content={+noReading}
                >
                  <MaterialSymbolsCircleNotificationsOutline
                    className="!text-2xl cursor-pointer"
                    onClick={() => {
                      history.push(PageEnum.MESSAGE);
                    }}
                  />
                </BaseBadge>
              </>
            )}

            {!isLogin && (
              <div
                className="flex items-center text- text-primaryButtonTextColor bg-primary rounded-md justify-center  !py-0 h-[26px] w-[70px] text-xs cursor-pointer"
                onClick={() => {
                  history.push(PageEnum.LOGIN);
                }}
              >
                <span className="select-none">{t('登录')}</span>
              </div>
            )}
          </NavbarContent>
        </Navbar>
        <div className="h-[60px]"></div>
      </div>
    )
  );
}
