import { ClosePlus } from '@/assets/icons/comm/ClosePlus';
import BaseModal from '@/components/base/baseModal';
import { basicRoutes } from '@/router/basicRoutes';
import { getHomeNoticeApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUnmount } from 'ahooks';
import { useEffect, useRef, useState } from 'react';

/// 整体消息弹框 ， 消息来着卡片
export default () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useModel('user');
  const { homeNotice } = useModel('socket');
  const prevId = useRef<any>({});

  useEffect(() => {
    if (
      user?.id &&
      homeNotice?.title &&
      window.location.pathname === '/home'
    ) {
      if (prevId.current?.id === homeNotice?.id && prevId.current?.updateTime === homeNotice?.updateTime) return;
      prevId.current = homeNotice;
      onOpen();
    }
  }, [location.pathname, user, homeNotice]);

  useUnmount(() => {
    onClose();
  });

  const modalClose = () => {
    onClose();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={modalClose}
      placement="center"
      classNames={{
        modal: 'border-none !p-0 overflow-visible !bg-[transparent]',
        content: 'border-none p-0 overflow-visible',
        title: 'hidden',
      }}
    >
      <div className="w-[80vw] relative  sm:max-w-[500px]">
        <div className="bg-background dark:bg-[#1A1F29]">
          <div className="relative py-2 overflow-hidden">
            <img
              className="absolute top-0 bottom-0 left-0 right-0 w-full object-cover"
              src={require('@/assets/img/announcementModalBg.png')}
              alt=""
            />
            <div className="relative z-[11] px-2 text-[#fff] text-[18px] leading-[20px] ">
              {homeNotice?.title}
            </div>
          </div>
          <div className="px-4 py-4 left-0 w-full">

            {homeNotice?.content && (
              <div
                className="max-h-[240px] overflow-y-auto overflow-x-hidden text-sm"
                dangerouslySetInnerHTML={{
                  __html: homeNotice?.content?.replace(/\n/g, '<br />'),
                }}
              />
            )}
          </div>
        </div>
        <div className="flex justify-center mt-2">
          <ClosePlus
            className="text-[#fff]"
            onClick={() => {
              modalClose();
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};
