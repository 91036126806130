import { useModel } from '@@/exports';
import { useRouteProps } from '@umijs/max';
import { useEffect, useState } from 'react';

const useAutoScroll = (): [number, () => void] => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const route = useRouteProps() || {};
  const { path } = route;
  const { isMobile } = useModel('system');

  // 根据当前的路径生成一个唯一的键来保存和恢复滚动位置
  const getPageKey = (path: any) => {
    return path === '/' ? 'app_home' : `app_${path}`;
  };

  const saveScrollPosition = () => {
    const pageKey = getPageKey(path);
    localStorage.setItem(pageKey, String(window.scrollY));
  };

  const restoreScrollPosition = () => {
    const pageKey = getPageKey(path);
    const position = localStorage.getItem(pageKey);

    if (!isMobile) return;

    if (route?.isTab && position) {
      // 使用requestAnimationFrame确保DOM已经加载
      requestAnimationFrame(() => {
        window.scrollTo({
          top: parseInt(position, 0),
        });
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', saveScrollPosition);

    return () => {
      window.removeEventListener('scroll', saveScrollPosition);
    };
  }, [path]); // 当路径改变时，更新事件监听器

  useEffect(() => {
    // 页面加载或路径变化时恢复滚动位置
    restoreScrollPosition();
  }, [path]); // 当路径改变时，尝试恢复滚动位置并在卸载前保存

  // 实时更新滚动位置状态
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return [scrollPosition, saveScrollPosition];
};

export default useAutoScroll;
