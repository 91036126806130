import { useModel } from '@@/exports';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './components/ConfirmationModal';
import eventBus from '@/utils/evevtBus';
import SocketEnum from '@/enums/socketEnum';

export default () => {
  const ConfirmationModalRef =useRef<any>();
  useEffect(() => {
    eventBus.on(SocketEnum.OTHER_LOGIN, (val) => {
      if (val) {
        ConfirmationModalRef?.current?.isOnOpen();
        // ConfirmationModalRef?.current?.handleStartCountdown();
      }
      // else if(val=== SocketEnum.FORCE_LOGOUT){
      //   ConfirmationModalRef?.current?.onClose();
      // }
     });
  }, []);

  return (
    <>
      <ConfirmationModal ref={ConfirmationModalRef} />
    </>
  );
};
