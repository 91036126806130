import account from '../assets/tabicon/account.json';
import compass from '../assets/tabicon/compass.json';
import market from '../assets/tabicon/market.json';
import swap from '../assets/tabicon/swap.json';
import wallet from '../assets/tabicon/wallet.json';
import PageEnum from '../enums/pageEnum';
import { IRouteType } from './routesType'; // 底部导航使用

// 底部导航使用
export const tabBarRoutes: IRouteType[] = [
  {
    // 首页路由
    path: PageEnum.HOME,
    component: '@/pages/home/HomeView',
    i18n: '首页',
    isTab: true,
    icon: account,
    noGoTop: true,
    stillness: true,
  },
  {
    // 市场路由
    path: PageEnum.MARKET,
    component: '@/pages/public/market/MarketView',
    i18n: '市场',
    isTab: true,
    stillness: true,
    icon: market,
    noGoTop: true,
  },
  {
    path: PageEnum.TRANSACTION,
    component: '@/pages/transaction/TransactionView',
    i18n: '交易',
    isTab: true,
    motionType: 'alpha',
    // noTopNav: true,
    noTopNav: true,
    icon: swap,
    stillness: true,
  },
  {
    // 合约路由
    path: PageEnum.CONTRACT,
    component: '@/pages/contractTrade',
    i18n: '合约',
    icon: compass,
    noGoTop: true,
    noTopNav: true,
    isTab: true,
    stillness: true,
  },
  {
    // 资金路由
    path: PageEnum.FUNDS,
    component: '@/pages/funds/index/FundsView',
    i18n: '资金',
    isTab: true,
    icon: wallet,
    noGoTop: true,
    noTopNav: true,
    stillness: true,
  },
];
