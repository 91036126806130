import type { SVGProps } from 'react';

export function MaterialSymbolsCircleNotificationsOutline(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_246_38)">
        <path
          d="M13.25 17.5H6.5C6.375 17.5 6.4375 17.625 6.5625 17.875C6.75 18.125 7 18.4375 7.3125 18.75C7.625 19.0625 8.0625 19.375 8.5 19.625C8.9375 19.875 9.4375 20 9.9375 20H10.0625C10.5625 20 11.0625 19.875 11.5 19.625C11.9375 19.375 12.3125 19.0625 12.6875 18.75C13 18.4375 13.25 18.125 13.4375 17.875C13.625 17.625 13.625 17.5 13.5 17.5H13.25ZM19.8125 15.3125C19.5 14.875 19.125 14.3125 18.75 13.6875C18.375 13.0625 18 12.375 17.625 11.625C17.25 10.875 16.9375 10.0625 16.6875 9.25C16.4375 8.375 16.3125 7.5625 16.3125 6.6875C16.3125 5.8125 16.1875 5.0625 15.9375 4.375C15.6875 3.6875 15.375 3.0625 15 2.5C14.625 1.9375 14.1875 1.5 13.625 1.125C13.125 0.75 12.5625 0.5 12 0.3125C11.375 0.125 10.6875 0.0625 10 0.0625H9.9375H9.875C9.1875 0.0625 8.5 0.1875 7.875 0.3125C7.3125 0.5 6.75 0.75 6.25 1.125C5.8125 1.5 5.3125 1.9375 4.9375 2.4375C4.5625 3 4.25 3.5625 4 4.3125C3.8125 5 3.6875 5.8125 3.6875 6.625C3.6875 7.5 3.5625 8.375 3.3125 9.1875C3.0625 10 2.75 10.8125 2.375 11.5625C2 12.3125 1.625 13 1.25 13.625C0.875 14.25 0.5 14.8125 0.1875 15.25C0.0625 15.4375 0 15.5625 0 15.75C0 15.875 0.0625 16 0.125 16.125C0.1875 16.25 0.25 16.3125 0.375 16.375C0.4375 16.4375 0.5 16.4375 0.5625 16.4375H19.4375C19.5 16.4375 19.5625 16.4375 19.625 16.375C19.75 16.3125 19.8125 16.25 19.875 16.125C19.9375 16 20 15.875 20 15.75C20 15.5625 19.9375 15.4375 19.8125 15.3125Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_38">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}
