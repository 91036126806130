import { useTheme } from 'next-themes';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//弹出消息层
export default function MyToastContainer({}: any) {
  const { theme } = useTheme();

  return (
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme={theme}
      limit={2}
      transition={Zoom}
    />
  );
}
