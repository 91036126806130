import { IcSharpLanguage } from '@/assets/icons/layout/IcSharpLanguage';
import { PhMoonFill } from '@/assets/icons/layout/PhMoonFill';
import { RiSunFill } from '@/assets/icons/layout/RiSunFill';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import { basicRoutes } from '@/router/basicRoutes';
import { useModel } from '@@/exports';
import { cn, Image } from '@nextui-org/react';
import { history, useRouteProps } from '@umijs/max';
import { useTheme } from 'next-themes';

/**
 * TopNav组件：用于展示顶部导航栏。
 * 该组件会根据当前主题和路由信息，决定是否渲染导航栏，并提供主题切换的功能。
 *
 * 无参数
 * 返回值: 渲染的顶部导航栏React元素或null（取决于当前路由是否在白名单内）
 */
export default function TopNav() {
  // 使用useTheme钩子获取当前主题和设置主题的函数
  const { theme, setTheme } = useTheme();
  // 使用useRouteProps钩子获取当前路由信息，如果没有则返回空对象
  const route: any = useRouteProps() || {};
  // 将基础路由映射为路径列表，用于后续的白名单检查
  const whiteList = basicRoutes.map((item) => item.path);
  // 处理主题切换的函数
  const handleThemeChange = () => {
    if (theme === ThemeEnum.light) {
      setTheme(ThemeEnum.dark);
    } else {
      setTheme(ThemeEnum.light);
    }
  };


  const { appInfo } = useModel('appInfo');
  const { isMobile } = useModel('system');
  return (
    whiteList.includes(route.path) && (
      <div
        className={cn(
          `h-[60px] flex items-center justify-between px-4 text-foreground border-b-[1px] border-backgroundAuxiliaryColor w-full bg-background flex-shrink-0`,
          {
            'fixed !bg-[transparent] top-0  bg-[transparent z-[6] border-none px-20':
              !isMobile,
          },
        )}
      >
        <div>
          <div className="flex items-center text-iconBrandColor">
            <Image
              src={(appInfo as any)?.APP_BASE_LOGO}
              className="w-[26px] mr-2"
              alt="logo"
              onClick={async () => {
                localStorage.setItem('APP_ARRANGEMENT_I18N', ''); //清理缓存
    
                if ('serviceWorker' in navigator) {
                  const registrations =
                    await navigator.serviceWorker.getRegistrations();
                  if (registrations.length > 0) {
                    for (let registration of registrations) {
                      await registration.unregister();
                      console.log('Service Worker 已注销');
                    }
                  } else {
                    console.log('没有注册的 Service Worker');
                  }
                } else {
                  console.log('Service Worker 不受支持');
                }
    
                window.location.reload();
              }}
            />
          </div>
        </div>

        <div className="mb-0 text-foreground sm:hidden flex items-center gap-3 ">
          <div
            onClick={() => {
              history.push(PageEnum.USER_LANGUAGE);
            }}
            className="flex items-center text-titleColor  p-2 !pl-0  box-content text-xs cursor-pointer select-none"
          >
            <IcSharpLanguage className="!text-2xl cursor-pointer text-foreground" />
          </div>

          {theme === ThemeEnum.light && (
            <PhMoonFill
              onClick={handleThemeChange}
              className="!text-2xl cursor-pointer"
            />
          )}
          {theme === ThemeEnum.dark && (
            <RiSunFill
              onClick={handleThemeChange}
              className="!text-2xl cursor-pointer"
            />
          )}
        </div>
      </div>
    )
  );
}
