import { useModel } from '@@/exports';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReturnTrialMoneyModal from './components/ReturnTrialMoneyModal';
import eventBus from '@/utils/evevtBus';
import SocketEnum from '@/enums/socketEnum';
import { Button, useDisclosure } from '@nextui-org/react';

export default () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ReturnTrialMoneyModalRef = useRef<any>(null);
  useEffect(() => {
    // 监听事件 【体验金弹窗】
    eventBus.on(SocketEnum.EXPERIENCE_FUND_EXPIRED, async(val) => {
      if(!isOpen&&!ReturnTrialMoneyModalRef?.current?.ConfirmAgainIsOpen){
        onOpen();
      }
     });
    // return () => {
    //   eventBus.off(SocketEnum.EXPERIENCE_FUND_EXPIRED);
    // };
  }, []);

  return (
    <>
      <ReturnTrialMoneyModal isOpen={isOpen} onClose={onClose} ref={ReturnTrialMoneyModalRef} />
    </>
  );
};
