import useAutoScroll from '@/hooks/useAutoScroll';
import ContractTrade from '@/pages/contractTrade';
import FundsView from '@/pages/funds/index/FundsView';
import HomeView from '@/pages/home/HomeView';
import MarketView from '@/pages/public/market/MarketView';
import TransactionView from '@/pages/transaction/TransactionView';
import { tabBarRoutes } from '@/router/tabBarRoutes';
import { useModel, useRouteProps } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useTheme } from 'next-themes';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import KeepAlive from 'react-activation';
import ContractView from '@/pages/contract/ContractView';

// 渲染所有 Tab 页面，并根据活动状态显示或隐藏

function AppTabs() {
  const [activeTab, setActiveTab] = useState(null);
  const route: any = useRouteProps() || {};

  //渲染一级页面，点击才加入到状态中
  const [tabLsit, setTabLsit] = useState<any>([]);
  const { language } = useModel('language');
  const { orderKey } = useModel('system');

  const path = route.path;
  const isTab = route.isTab;
  useLayoutEffect(() => {
    // 如果是 Tab 页面，添加到 tabs 集合中
    if (route.isTab) {
      setActiveTab(path);
      if (!tabLsit.includes(path)) {
        setTabLsit((old: any) => [...old, path]);
      }
    }
  }, [path]);
  /**
   * useAutoScroll 滚动记住页面滚动位置
   */
  useAutoScroll();

  const getTabBarRoutes = () => {
    return tabBarRoutes.map((item: any) => {
      let component = null;
      if (item.component === '@/pages/home/HomeView') {
        component = HomeView;
      }
      if (item.component === '@/pages/public/market/MarketView') {
        component = MarketView;
      }
      if (item.component === '@/pages/transaction/TransactionView') {
        component = TransactionView;
      }
      // if (item.component === '@/pages/contract/ContractView') {
      //   component = ContractView;
      // }

      if (item.component === '@/pages/contractTrade') {
        component = ContractTrade;
      }

      if (item.component === '@/pages/funds/index/FundsView') {
        component = FundsView;
      }
      return { ...item, component };
    });
  };
  //使用主题
  const { theme } = useTheme();
  const [layoutKey, setLayoutKey] = useState(0);
  //网络状态
  useUpdateEffect(() => {
    if (navigator.onLine) {
      setLayoutKey((prevState) => prevState + 1);
    }
  }, [navigator.onLine]);


  const dom = useMemo(() => {
    return getTabBarRoutes()
      ?.filter((i) => {
        return tabLsit.includes(i.path);
      })
      ?.map((tab: any) => {
        const Component = tab.component;
        const tabPath = tab?.path;
        let display = 'none';
        if (activeTab === tabPath && isTab) {
          display = 'block';
        }

        let key = tabPath + theme + language + layoutKey;
        // if ('/funds' === tabPath) {
        //   key += orderKey;
        // }

        return (
          <div key={key} style={{ display: display }}>
            <KeepAlive
              name={key}
              id={key}
              when={true}
              autoFreeze={true}
              saveScrollPosition
            >
              <Component isActive={activeTab === tabPath} />
            </KeepAlive>
          </div>
        );
      });
  }, [tabLsit, path, activeTab, isTab, orderKey, layoutKey]);

  return dom;
}

export default AppTabs;
