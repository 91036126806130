import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseModal from '@/components/base/baseModal';
import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { toast } from '@/layouts/components/ToastMessage';
import {
  cancelDeviceLoginApi,
  returnExperiencePriceApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import { Button } from '@nextui-org/react';
import { useCountDown } from 'ahooks';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReturnTrialMoneyModalConfirmAgain from './ReturnTrialMoneyModal_ConfirmAgain';

export default forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const { isOpen, onClose } = props;
  //  再次
  const ReturnTrialMoneyModalConfirmAgainRef = useRef<any>();
  // 归还体验金 接口
  const {
    run: returnExperiencePriceReq,
    loading: returnExperiencePriceLoading,
  } = useReq(returnExperiencePriceApi, {
    manual: true,
    loadingDefault:false,
    onSuccess: (res: any) => {
      toast.success(t('操作成功'));
      onClose();
    },
  });

  //   确认
  const handleConfirm = () => {
    returnExperiencePriceReq();
  };
  // 取消
  const handleCancel = () => {
    onClose();
    setTimeout(() => {
      ReturnTrialMoneyModalConfirmAgainRef?.current?.isOnOpen();
    }, 300);
  };
  // 暴露方法
  useImperativeHandle(ref, () => ({
    ConfirmAgainIsOpen:ReturnTrialMoneyModalConfirmAgainRef?.current?.isOpen
  }));
  return (
    <>
      <div className="w-full">
        <BaseModal
          title={t('体验金归还')}
          isOpen={isOpen}
          onClose={onClose}
          clickMaskClose={false}
          placement="center"
          classNames={{
            modal: 'w-[90%] !px-4 !pb-4',
            animated: '!px-8',
            content:
              'prose prose-zinc max-w-none dark:prose-invert bg-background w-full',
            title: '!justify-center text-[18px] pt-[8px]  font-semibold',
          }}
        >
          <div className="w-full flex flex-wrap">
            <div className="w-full text-[16px] leading-[22px] text-foreground">
              {t(
                '公测结束，是否结束交易并归还公测基金?',
              )}
            </div>
            {/* 按钮 */}
            <div className="w-full mt-2 flex justify-between gap-3">
              <Button
                className="w-full "
                color="primary"
                isLoading={returnExperiencePriceLoading}
                spinner={<LoadingSvg />}
                onClick={() => handleConfirm()}
              >
                <span className='text-white'>{t('是')}</span>
              </Button>
              <Button className="w-full " onClick={() => handleCancel()}>
                {t('否')}
              </Button>
            </div>
          </div>
        </BaseModal>
        {/* 再次确认 */}
        <ReturnTrialMoneyModalConfirmAgain ref={ReturnTrialMoneyModalConfirmAgainRef} />
      </div>
    </>
  );
});
