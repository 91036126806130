import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseModal from '@/components/base/baseModal';
import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { toast } from '@/layouts/components/ToastMessage';
import {
  cancelDeviceLoginApi,
  confirmDeviceLoginApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import { Button, useDisclosure } from '@nextui-org/react';
import { useCountDown } from 'ahooks';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

export default forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { logOut } = useModel('user');

  // 倒计时
  const [targetDate, setTargetDate] = useLocalStorage({
    key: CacheEnum.APP_MULTIPLE_DEVICE_CONFIRM,
    defaultValue: 0,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  // 确认接口
  const { run: runConfirmDeviceLoginApi, loading: confirmDeviceLoginLoading } =
    useReq(confirmDeviceLoginApi, {
      manual: true,
      loadingDefault: false,
      onSuccess: (res: any) => {
        setTargetDate(undefined);
        toast.success(t('您已选择保持当前设备在线。其他设备已被强制登出。'));
        onClose();
        // eventBus.off('OTHER_LOGIN');
      },
    });

  // 取消接口
  const { run: runCancelDeviceLoginApi, loading: cancelDeviceLoginLoading } =
    useReq(cancelDeviceLoginApi, {
      manual: true,
      loadingDefault: false,
      onSuccess: (res: any) => {
        setTargetDate(undefined);
        // toast.success(t('您已选择切换到其他设备登录。本设备已被强制登出。'));
        onClose();
        // // 返回登陆页面
        // logOut();
        // eventBus.off('OTHER_LOGIN');
      },
    });

  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      if (isOpen) {
        runCancelDeviceLoginApi();
      }
    },
  });

  //   确认
  const handleConfirm = () => {
    runConfirmDeviceLoginApi();
  };

  // 取消
  const handleCancel = () => {
    runCancelDeviceLoginApi();
  };

  // 开启倒计时10s
  const handleStartCountdown = () => {
    setTargetDate(Date.now() + 1000 * 10);
  };

  useEffect(() => {
    if (isOpen && countdown === 0) {
      handleStartCountdown();
    }
  }, [isOpen]);
  
  // 暴露方法
  useImperativeHandle(ref, () => ({
    isOnOpen: onOpen,
    onClose,
    handleStartCountdown,
  }));
  return (
    <>
      <div className="w-full">
        <BaseModal
          title={t('设备登陆确认')}
          isOpen={isOpen}
          onClose={onClose}
          clickMaskClose={false}
          placement="center"
          classNames={{
            modal: 'w-full !px-4 !pb-4',
            animated: '!px-8',
            content:
              'prose prose-zinc max-w-none dark:prose-invert bg-background w-full',
            title: '!justify-center text-[18px] pt-[8px]  font-semibold',
          }}
        >
          <div className="w-full flex flex-wrap">
            <div className="w-full text-[16px] leading-[22px] text-foreground">
              {t('您的账号已在其他设备上登录，是否保持当前设备在线。')}
            </div>
            {/* 按钮 */}
            <div className="w-full mt-2 flex justify-between gap-3">
              <Button
                className="w-full text-[#fff]"
                color="primary"
                isLoading={confirmDeviceLoginLoading}
                spinner={<LoadingSvg />}
                onClick={() => handleConfirm()}
              >
                {t('确认')}
              </Button>
              <Button
                className="w-full "
                isLoading={cancelDeviceLoginLoading}
                spinner={<LoadingSvg />}
                onClick={() => handleCancel()}
              >
                {t('取消')}
                {countdown === 0 || cancelDeviceLoginLoading
                  ? ''
                  : `（${Math.round(countdown / 1000)}s）`}
              </Button>
            </div>
          </div>
        </BaseModal>
      </div>
    </>
  );
});
