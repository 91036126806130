import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import SuspendButton from './SuspendButton';
import { history }  from "@umijs/max"

/// 悬浮 代理
function DraggableProxyIcon() {
  // 点击事件处理函数

  // url pathnameDraggableProxyIcon
  const pathname = window.location.pathname;

  const [isOpenProxy, setIsOpenProxy] = useState(false);
  const [apiUrl, setApiUrl] = useState<any>(null);

  useEffect(() => {
    setApiUrl(localStorage.getItem('window.apiUrl'));

    const localVal = apiUrl || localStorage.getItem('window.soketUrl') || localStorage.getItem('window.imSoketUrl');
    if (localVal && localStorage.getItem('window.apiUrl')?.includes('http')) {
      setIsOpenProxy(true);
    }
  }, [pathname]);

  if (!isOpenProxy) return null;

  return (
    <div
      style={{
        position: 'fixed',
        cursor: 'move',
        zIndex: 9999999,
      }}
    >
      <SuspendButton
        style={
          {
            width: 38,
            height: 38,
          } as any
        }
        // img={require('@/assets/img/net.png')}
        renderContent={() => (
          <Popover color={'default'} placement="top">
            <PopoverTrigger>
              <img src={require('@/assets/img/net.png')} alt="" />
            </PopoverTrigger>
            {
             apiUrl&& <PopoverContent>
                <div className="px-1 py-2" onClick={()=>{history.push('/proxy')}}>
                  <div className="text-small font-bold">Agent In Progress</div>
                  <div className="text-tiny ">
                    Ip: <span> {apiUrl?.split('//')[1]}</span>
                  </div>
                </div>
              </PopoverContent>
            }
          </Popover>
        )}
      />
    </div>
  );
}

export default DraggableProxyIcon;
