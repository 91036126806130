import type { SVGProps } from 'react';

export function ClosePlus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="16" fill="transparent" />
      <path
        d="M12.4524 25.7797L18.2322 19.9999L12.4526 14.2202L14.2204 12.4525L20 18.2321L25.7796 12.4525L27.5474 14.2202L21.7678 19.9999L27.5476 25.7797L25.7799 27.5475L20 21.7676L14.2201 27.5475L12.4524 25.7797Z"
        fill="currentColor"
      />
      <path
        d="M20 2.5C29.665 2.5 37.5 10.335 37.5 20C37.5 29.665 29.665 37.5 20 37.5C10.335 37.5 2.49999 29.665 2.5 20C2.5 10.335 10.335 2.49999 20 2.5ZM20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
