import { useModel } from '@umijs/max';
import { useEffect } from 'react';

export const useResetIsHideBottomNav = ()=>{
  const {setIsHideBottomNav} = useModel("compontentSetting")

  useEffect(() => {
    setIsHideBottomNav(false)
  }, [location.pathname]);

}