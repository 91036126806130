import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import {
  MaterialSymbolsCircleNotificationsOutline,
} from '@/assets/icons/comm/MaterialSymbolsCircleNotificationsOutline';
import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import { IcSharpLanguage } from '@/assets/icons/layout/IcSharpLanguage';
import { MaterialSymbolsHeadphones } from '@/assets/icons/mine/MaterialSymbolsHeadphones';
import PageEnum from '@/enums/pageEnum';
import { basicRoutes } from '@/router/basicRoutes';
import { Badge, Navbar, NavbarBrand } from '@nextui-org/react';
import { history, useModel, useRouteProps } from '@umijs/max';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInViewport, useUpdateEffect } from 'ahooks';
import { useReq } from '@/services/net/request';
import { imGroupListApi } from '@/services/api/requestApi';
import BaseBadge from '@/components/base/baseBadge';

/**
 * 默认导出一个函数组件，用于根据当前路由和主题渲染导航栏。
 * 该组件会根据当前的主题切换主题，以及在白名单路由之外的地方渲染导航栏。
 *
 * 无参数。
 * 无显式返回值，但会根据条件渲染 Navbar 组件。
 */
export default function TopNav() {
  // 获得未读数字
  const { noReading } = useModel('socket');

  // 使用useRouteProps钩子获取当前路由信息，如果没有则返回空对象
  const { gotoGlobalCustomerService, imGroupList } = useModel('imSocket');

  const [isInvisible, setIsInvisible] = useState(false);
  const { appInfo } = useModel('appInfo');
  const { customerServiceMessageUNREAD, c2cServiceMessageUNREAD } = useModel('imSocket') || {};
  // 检查当前路由是否在白名单内，如果是则渲染导航栏组件
  const { localContract } = useModel('contract') || {};


  const unReadCount = useMemo(() => {
    return imGroupList?.reduce?.((acc: any, cur = {} as any) => {
      if (cur?.type === 5) {
        return acc + (customerServiceMessageUNREAD === false ? cur?.unreadCount : +customerServiceMessageUNREAD);
      }
      return acc + (c2cServiceMessageUNREAD?.[cur?.groupSign] === undefined ? cur?.unreadCount : c2cServiceMessageUNREAD?.[cur?.groupSign]);
    }, 0);
  }, [imGroupList, customerServiceMessageUNREAD, c2cServiceMessageUNREAD]);


  return (
    <div>
      <Navbar
        maxWidth="full"
        classNames={{ wrapper: 'p-0' }}
        className={`h-[60px]  sm:border-b-[1px] fixed border-backgroundAuxiliaryColor bg-background flex-shrink-0`}
      >
        <NavbarBrand>
          <div className="flex items-center ">
            {appInfo?.APP_BASE_LOGO ? (
              <img
                src={appInfo?.APP_BASE_LOGO}
                className="w-[24px] mr-3 cursor-pointer p-4 box-content"
                alt="logo"
                onClick={() => {
                  history.push(PageEnum.MINE);
                }}
              />
            ) : (
              <BasilUserSolid
                className="ml-3 mr-3 text-2xl text-titleColor"
                onClick={() => {
                  history.push(PageEnum.MINE);
                }}
              />
            )}

            <div
              className="bg-backgroundAuxiliaryColor w-44 py-1.5 pl-4 rounded-md flex items-center"
              onClick={() => {
                history.push(PageEnum.SEARCH);
              }}
            >
              <BasilSearchSolid className=" text-iconFontColor text-xl" />
              <span className="text-iconFontColor text-xs ml-2">
                  {localContract?.alias}
                </span>
            </div>
          </div>
        </NavbarBrand>

        <div className="flex items-center justify-between pr-2 text-foreground ">

          <div
            onClick={() => {
              history.push(PageEnum.USER_LANGUAGE);
            }}
            className="flex items-center text-titleColor  px-2 !pl-0    text-xs cursor-pointer select-none"
          >
            <IcSharpLanguage className="!text-2xl cursor-pointer text-foreground" />
          </div>

          {/* 客服 icon */}
          <div className="px-2">
            <BaseBadge
              content={unReadCount}
              onClick={() => {
                gotoGlobalCustomerService();
              }}
            >
              <MaterialSymbolsHeadphones
                width={16}
                height={16}
                className=" cursor-pointer   box-content"
              />
            </BaseBadge>
          </div>
          <div className="px-2">
            <BaseBadge
              content={+noReading}
              onClick={() => {
                history.push(PageEnum.MESSAGE);
              }}
            >
              <MaterialSymbolsCircleNotificationsOutline
                width={16}
                height={16}
                className="    box-content cursor-pointer"

              />
            </BaseBadge>
          </div>

        </div>
      </Navbar>
      <div className="h-[60px]"></div>
    </div>
  );
}
