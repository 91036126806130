import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseModal from '@/components/base/baseModal';
import { returnExperiencePriceApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { Button, useDisclosure } from '@nextui-org/react';
import { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

export default forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose, } = useDisclosure();
    // 归还体验金 接口
    const {
      run: returnExperiencePriceReq,
      loading: returnExperiencePriceLoading,
    } = useReq(returnExperiencePriceApi, {
      manual: true,
      loadingDefault:false,
      onSuccess: (res: any) => {
        toast.success(t('操作成功'));
        onClose();
        // eventBus.off('OTHER_LOGIN');
      },
    });
  //   确认
  const handleConfirm = () => {
    returnExperiencePriceReq();
  };
  //  取消
  const handleCancel = () => {
    toast.warning(t('您的账户交易功能和提现功能已锁定，请联系客服!'));
    onClose();
  };
  // 暴露方法
  useImperativeHandle(ref, () => ({
    isOnOpen:onOpen,
    isOpen
  }));
  return (
    <>
      <div className="w-full">
        <BaseModal
          title={t('体验金归还')}
          isOpen={isOpen}
          onClose={onClose}
          clickMaskClose={false}
          placement="center"
          classNames={{
            modal: 'w-full !px-4 !pb-4',
            animated:"!px-4",
            content:
              'prose prose-zinc max-w-none dark:prose-invert bg-background w-full',
            title: '!justify-center text-[18px] pt-[8px]  font-semibold',
          }}
        >
          <div className="w-full flex flex-wrap">
            <div className="w-full text-[16px] leading-[22px] text-foreground">
              {t(
                '您的账户交易功能和提现功能将会被锁定，请再次确认是否归还体验基金?',
              )}
            </div>
            {/* 按钮 */}
            <div className="w-full mt-2 flex justify-between gap-3">
              <Button
                className="w-full "
                color="primary"
                isLoading={returnExperiencePriceLoading}
                spinner={<LoadingSvg />}
                onClick={handleConfirm}
              >
                {t('是')}
              </Button>
              <Button className="w-full " onClick={handleCancel}>
                {t('否')}
              </Button>
            </div>
          </div>
        </BaseModal>
      </div>
    </>
  );
});
