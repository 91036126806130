import BaseModal from '@/components/base/baseModal';
import BasePromptWindow from '@/components/base/basePromptWindow';
import FeaturesRefreshQuery from '@/components/features/featuresRefreshQuery';
import { WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import ThemeEnum from '@/enums/themeEnum';
import AppTabs from '@/layouts/components/AppTabs';
import BottomTabBar from '@/layouts/components/BottomTabBar';
import LoggedInNavigation from '@/layouts/components/LoggedInNavigation';
import LoggedInPCNavigation from '@/layouts/components/LoggedInPCNavigation';
import NotLoggedInNavigation from '@/layouts/components/NotLoggedInNavigation';
import ProjectSetting from '@/setting/projectSetting';
import { useModel } from '@@/exports';
import { cn } from '@nextui-org/react';
import { useRouteProps } from '@umijs/max';
import { useMount, usePrevious, useUpdateEffect } from 'ahooks';
import { ConfigProvider as AntdConfigProvider, theme } from 'antd';
import { useTheme } from 'next-themes';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-photo-view/dist/react-photo-view.css';
import { Outlet } from 'umi';
import DraggableProxyIcon from './components/DraggableProxyIcon';
import FundsReturnTrialMoneyModal from './components/FundsReturnTrialMoneyModal';
import LoginConfirmationModal from './components/LoginConfirmationModal';
import MyToastContainer from './components/MyToastContainer';
import NetworkCheckProvidr from './components/NetworkCheckProvidr';
import NextProviders from './components/NextUIProvider';
import ToastMessage from './components/ToastMessage';
import { basicRoutes } from '@/router/basicRoutes';
import RenderUtil from '@/utils/RenderUtil';
import { useResetIsHideBottomNav } from '@/layouts/hooks/useResetCompontentSetting';
import AnnouncementPopUpModal from '@/layouts/components/AnnouncementPopUpModal';

if ('serviceWorker' in navigator) {
  const registrations = await navigator.serviceWorker.getRegistrations();
  if (registrations.length > 0) {
    for (let registration of registrations) {
      await registration.unregister();
      console.log('Service Worker loggedOut');
    }
  } else {
    console.log('notRegistered Service Worker');
  }
} else {
  console.log('Service Worker notSupported');
}

/**
 * 页面入口 类似于APP组件
 * */
export default function Layout() {
  const { t } = useTranslation();
  // 基础定义
  const route: any = useRouteProps() || {};
  const { setPreRouter } = useModel('routing');
  const {appInfo} = useModel('appInfo');
  const prePathname = usePrevious(route.path);
  const whiteList = useMemo(() => {
    return basicRoutes.map((item) => item.path);
  }, [basicRoutes]);
  useEffect(() => {
    setPreRouter(prePathname);
  }, [prePathname]);
  const { language } = useModel('language');
  const {   requestUserInfo, refreshWallet, user} = useModel("user")
  const {
    gotoC2CCustomerService,
    gotoCustomerService,
    imGroupListReq,
    imGroupList,
    latestNews
  } = useModel('imSocket') || {};
  // 最新消息跳转客服
  useUpdateEffect(() => {
    if (!latestNews?.channelId || latestNews?.channelId === 'DEFAULT') {
      gotoCustomerService();
    } else {
      gotoC2CCustomerService({
        groupType: latestNews?.groupType,
        channelId: latestNews?.channelId,
        rechargeType: latestNews?.groupType,
      });
    }
  }, [latestNews]);
  // 获取群聊列表
  useEffect(() => {
    if (imGroupList?.length === 0 && user?.id) {
      imGroupListReq();
    }
  }, [ route.path, user]);

  const {
    isMobile,
    lockScroll,
    isPc,
    setContractOrderReady,
    setCoinOrderReady,
    orderKey,
    localChoiceContractSocket,
    setLocalChoiceContractSocket
  } = useModel('system');
  const { exchange, getExchangeData } = useModel('exchange');
  const { theme: nextUiTheme = ProjectSetting.APP_DEFAULT_THEME } = useTheme();
  const { refreshUserAccountInformation, updateUserAmount ,getSocketRowByName} =
    useModel('socket');

  // 需要展示在行情游览器头部的路由
  const isHasBrowserTitle=[
    PageEnum.HOME,
    PageEnum.TRANSACTION,
    PageEnum.CONTRACT,
  ]
  //不需要底部 pb-[60px] 的路由
  const noPaddding: PageEnum[] = [
    PageEnum.ONLINESUPPORT,
    PageEnum.REBATE_RANKING,
    PageEnum.UPGRADEPLAN,
    PageEnum.TRANSFER,
    PageEnum.APPLY_VIP,
    PageEnum.INVITEFRIENDS,
    PageEnum.INVITEQRCODE,
    PageEnum.INVITE,
    PageEnum.XRPTOPUP,
    PageEnum.CHOOSE_AREA,
  ];
  //不需要限制最大宽度的路由
  const fullRouterPath: PageEnum[] = [
    PageEnum.HOME,
    PageEnum.LOGIN,
    PageEnum.REGISTERED,
    PageEnum.FORGOT,
  ];
  // 监听路由，登录成功调用接口
  useEffect(() => {
    if (prePathname === PageEnum.LOGIN && route.path === PageEnum.HOME) {
      requestUserInfo?.();
    }
    // validRepeatLoginReq?.();
  }, [prePathname]);
  // 路由监听刷新钱包(更新是否能刷新合约订单)
  // 更新合约订单请求状态
  const getContractOrderReady = (path: string, search: string) => {
    // 如果是首页，必须是桌面端并且参数是合约
    if (
      path === PageEnum.HOME &&
      !isMobile &&
      [`?type=${WalletTypeEnum.CONTRACT}`, ''].includes(search)
    ) {
      return true;
    }
    // 如果是在订单页面并且参数类型是合约订单
    if (
      path === PageEnum.ORDER &&
      search === `?type=${WalletTypeEnum.CONTRACT}`
    ) {
      return true;
    }
    // 如果是合约页面和资金页面无脑刷新
    return [PageEnum.CONTRACT, PageEnum.FUNDS].includes(path as any);
  };

    // 路由拦截
    useEffect(() => {
      if(!isHasBrowserTitle.includes(route?.path)){
        document.title = appInfo?.APP_BASE_NAME??t("加载中")
        setLocalChoiceContractSocket(null)
        return 
      }
      if (route&&isHasBrowserTitle.includes(route?.path)) {
          // 判断是否选择合约或者股票
        const socketData = getSocketRowByName(localChoiceContractSocket?.symbol); 

        document.title = `${localChoiceContractSocket?.symbol?RenderUtil.FormatAmount(socketData?.a,localChoiceContractSocket?.priceAccuracy??2,true)+" "+
          (localChoiceContractSocket?.alias ?? localChoiceContractSocket?.name)
         +" | ":""} ${appInfo?.APP_BASE_NAME??t("加载中")}`;
         return
      }
    }, [route,localChoiceContractSocket]);
  // 更新币币订单状态
  const getCoinOrderReady = (path: string, search: string) => {
    // 如果是首页，必须是桌面端并且参数是币币
    if (
      path === PageEnum.HOME &&
      !isMobile &&
      search === `?type=${WalletTypeEnum.COIN}`
    ) {
      return true;
    }
    // 如果是在订单页面并且参数类型是币币订单
    if (path === PageEnum.ORDER && search === `?type=${WalletTypeEnum.COIN}`) {
      return true;
    }
    // 如果是币币页面和资金页面无脑刷新
    return path === PageEnum.TRANSACTION;
  };
  useEffect(() => {
    setContractOrderReady(
      getContractOrderReady(location.pathname, location.search),
    );
    setCoinOrderReady(getCoinOrderReady(location.pathname, location.search));
    if (
      [PageEnum.HOME, PageEnum.FUNDS].includes(location.pathname as any) &&
      isMobile
    ) {
      refreshWallet();
    }
  }, [location.search, location.pathname, isMobile]);
  // 调用更新钱包--传入socket指定需要刷新的账户
  useUpdateEffect(() => {
    refreshWallet();
  }, [updateUserAmount]);
  // socket指定刷新账户
  useUpdateEffect(() => {
    requestUserInfo?.({ noFund: true });
  }, [refreshUserAccountInformation]);
  // 如果没有汇率列表且登录了 请求汇率列表
  useEffect(() => {
    if (user?.id && !exchange) {
      getExchangeData();
    }
  }, [user, exchange, location.pathname]);
  // 刷新持仓或者委托中的币种id和对应的杠杆
  const { positionAndEntrustmentReq } = useModel('contract');
  useUpdateEffect(() => {
    if (
      location.pathname === PageEnum.HOME &&
      !isMobile &&
      [`?type=${WalletTypeEnum.CONTRACT}`, ''].includes(location.search)
    ) {
      positionAndEntrustmentReq();
    }

    if (
      [PageEnum.FUNDS, PageEnum.CONTRACT].includes(location.pathname as any) &&
      isMobile
    ) {
      positionAndEntrustmentReq();
    }
  }, [orderKey, location]);

  useMount(() => {
    if (window?.FlutterTheme) {
      window.FlutterTheme?.postMessage(nextUiTheme);
    }
    window.FlutterTheme?.postMessage(nextUiTheme);
    window.FlutterI18n?.postMessage(language);
  });


  const {
    successModalVisible,
    setSuccessModalVisible,
    reReNameTitle,
    reReNameText,
    setReReNameText,
  } = useModel('system');
  // 重置组件设置
  useResetIsHideBottomNav();

  return (
    <AntdConfigProvider
      key={nextUiTheme}
      theme={{
        inherit: false,
        algorithm:
          nextUiTheme === ThemeEnum.dark
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
        token: { colorPrimary: '#409EFF' },
        components: {
          Table: {
            headerBorderRadius: 0,
          },
        },
      }}
    >
      <NextProviders
        themeProps={{
          attribute: 'class',
          defaultTheme: ProjectSetting.APP_DEFAULT_THEME,
          themes: [ThemeEnum.dark, ThemeEnum.light],
        }}
      >
        {/*{route.path !== PageEnum.ONLINESUPPORT &&*/}
        {/*  route.path !== PageEnum.EXTERNAL_SERVICE && <DraggableServer />}*/}
        <div className="flex flex-col">
          {/*未登录页面导航*/}
          <NotLoggedInNavigation />
          {/*登录后移动端页面导航*/}
          {!route?.noTopNav &&
            !whiteList.includes(route.path) && isMobile && <LoggedInNavigation />}
          {/*登录后PC页面导航*/}
          {!isMobile && <LoggedInPCNavigation key={user?.id} />}
          {/*路透映射内容区*/}

          <div
            className={cn(
              'flex-1  sm:overflow-y-auto sm:overflow-x-hidden  bg-background text-foreground ',
              {
                'pb-[60px]': !noPaddding.includes(route.path),
              },
            )}
            key={route.path === '/mine/userLanguage' ? '' : language}
          >
            <div className="flex">
              {/* //移动端隐藏 pc端显示 */}
              <div
                className={cn(
                  {
                    'sm:!px-[100px]  sm:max-w-[1200px] flex-1':
                      !fullRouterPath.includes(route.path),
                  },
                  'ms:mt-2 mx-auto  w-full',
                )}
              >
                <div className="flex flex-col">
                  {/*路透映射内容区*/}
                  <div
                    className={cn('flex-1  bg-background text-foreground', {
                      'pb-[60px]': !noPaddding.includes(route.path),
                    })}
                  >
                    <BasePromptWindow>
                      {/* 网络检测 */}
                      {/* <RouterMotionProvidrs> */}
                      <div className="flex h-full">
                        <div
                          className={cn('flex-1 max-w-[100vw]  h-full', {
                            'flex-0   overflow-hidden': lockScroll,
                            'flex-1': !lockScroll,
                          })}
                        >
                          {(isPc || !route?.isTab) && <Outlet />}

                          {isMobile && <AppTabs />}

                          {/* app 一级底栏 */}
                        </div>
                      </div>
                      {/* </RouterMotionProvidrs> */}
                      <NetworkCheckProvidr />
                    </BasePromptWindow>

                    <FeaturesRefreshQuery />
                  </div>
                  {/*底部导航*/}
                  <div className="sm:hidden">
                    <BottomTabBar />
                  </div>

                  {/* 消息弹出 */}
                  <MyToastContainer />
                </div>
              </div>
            </div>
          </div>
          {/* 多设备登录确认弹窗 */}
          <LoginConfirmationModal />

          {/* 归还金额到期弹窗 */}
          <FundsReturnTrialMoneyModal />

          {/* // 开发代理显示 */}
          <DraggableProxyIcon />

          <BaseModal
            // isOpen={true}
            isOpen={successModalVisible}
            onClose={() => setSuccessModalVisible(false)}
            title={reReNameTitle}
            classNames={{
              title: 'text-[18px] pt-[8px]  font-semibold',
              modal: 'w-[90vw] p-4',
            }}
            placement="center"
          >
            <div className="mt-4 mb-10 px-4 break-words">{reReNameText}</div>
            <div
              onClick={() => {
                // onConfirm?.(isSelected);
                // onClose();
                setSuccessModalVisible(false);
              }}
              className="flex  sm:cursor-pointer justify-center items-center rounded-[8px] text-[14px] bg-primary text-primaryButtonTextColor px-5 h-[34px]"
            >
              {t('确认')}
            </div>
          </BaseModal>

          {/* 自己 手搓的 Toast 顶弹框 */}
          <ToastMessage />
          <AnnouncementPopUpModal  />


        </div>
      </NextProviders>
    </AntdConfigProvider>
  );
}
