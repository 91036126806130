import BaseModal from '@/components/base/baseModal';
import { basicRoutes } from '@/router/basicRoutes';
import { useRouteProps } from '@@/exports';
import { Button, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useTranslation } from 'react-i18next';

export default function FeaturesRefreshQuery() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  const { isRefresh, setIsRefresh } = useModel('socket');
  // 使用useRouteProps钩子获取当前路由信息，如果没有则返回空对象
  const route: any = useRouteProps() || {};
  // 将基础路由映射为路径列表，用于后续的白名单检查
  const whiteList = basicRoutes?.map((item) => item.path);
  useUpdateEffect(() => {
    if (isRefresh && !whiteList.includes(route.path)) {
      onOpen();
    }
  }, [isRefresh]);
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      placement="center"
      title={t('提示')}
    >
      <div className="w-[300px]  px-4 pb-6">
        <p className="text-center text-foreground">
          {t('系统已升级，请选择是否刷新页面升级！')}
        </p>
        <div className="flex justify-end w-full mt-6">
          <Button
            className="mainColorButton mx-2 !bg-backgroundAuxiliaryColor !py-0 !h-[36px]"
            onClick={onClose}
          >
            {t('取消')}
          </Button>
          <Button
            className="mainColorButton mx-2 !py-0 !h-[36px]"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('确认')}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
